export default [
    {
        id: 'meat',
        subMenu: ['삼겹살,돼지고기구이', '치킨', '찜닭', '닭갈비', '돈까스', '족발,보쌈', '갈비', '곱창,막창,대창', '육회', '소고기구이', '스테이크', '탕수육', '오리구이', '양꼬치',],
        state: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }, {
        id: 'soup',
        subMenu: ['순대국', '돼지국밥', '감자탕', '설렁탕', '육개장', '김치찌개', '부대찌개', '마라탕', '샤브샤브', '매운탕', '닭한마리', '백숙',],
        state: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }, {
        id: 'noodle',
        subMenu: ['짜장면,짬뽕', '냉면', '파스타', '칼국수', '쌀국수', '우동', '라멘', '잔치국수',],
        state: [0, 0, 0, 0, 0, 0, 0, 0,]
    },
    {
        id: 'rice',
        subMenu: ['볶음밥', '초밥', '비빔밥', '돈부리,텐동', '죽', '카레', '도시락', '밥버거', '백반'],
        state: [0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    {
        id: 'bread',
        subMenu: ['피자', '햄버거', '샌드위치', '토스트',],
        state: [0, 0, 0, 0,]
    },
    {
        id: 'abroad',
        subMenu: ['인도요리', '태국요리', '타코,브리또'],
        state: [0, 0, 0,]
    },
    {
        id: 'seafood',
        subMenu: ['생선구이', '회', '게장', '대게찜', '새우요리', '조개,골뱅이', '아귀찜,해물찜', '주꾸미,낙지'],
        state: [0, 0, 0, 0, 0, 0, 0, 0,]
    },
    {
        id: 'snackfood',
        subMenu: ['떡볶이', '김밥', '종합분식',],
        state: [0, 0, 0,]
    },
]

